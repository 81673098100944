<template>
    <div class="align-index-page">
        <full-loading ref="fullLoading"></full-loading>
        <!-- <asset-tabs :active="'align'"></asset-tabs> -->
        <div class="page-tools-content">
            <div class="left">
                <el-button type="grey" size="small" round icon="el-icon-plus" v-permission="`tools:alignmentRouter:new`" @click="dialog.show = true;">{{$t('tools.tools_alignIndex_page_btn_1')}}</el-button>
                <el-button size="small" plain round icon="el-icon-refresh-left" :loading="refreshLoading" style="margin-left:10px;" @click="initPage('reload')">{{$t('tools.tools_alignIndex_page_btn_2')}}</el-button><!-- :disabled="mergeBtnDisabled" -->
            </div>
            <div class="center flex flex-align-center flex-justify-center">
                <!-- <div class="free-notice">
                    <img src="../../../../static/images/limited-free.png" style="height:15px;" alt="free"/>
                    【语料对齐】不消耗流量
                </div> -->
            </div>
            <div class="right">
                <div style="width:220px;">
                    <el-input :placeholder="$t('tools.tools_alignIndex_page_input_placeholder_1')" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
                <div style="width:124px;margin-left:10px;">
                    <el-select v-model="searchForm.sort" size="small" :placeholder="$t('tools.tools_alignIndex_page_select_placeholder_1')">
                        <el-option key="1" value="1" :label="$t('tools.tools_alignIndex_page_select_option_1')"></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <card-list v-if="list.length>0"
            :type="`align`"
            :list="list"
            @action="executeAction"></card-list>
        <div class="none-list-block" v-else>
            <div class="none-files-content">
                <svg-icon name="ib-empty"></svg-icon>
                <div class="info" v-if="queryType === 'search'">{{$t('tools.tools_alignIndex_page_tips_1')}}</div>
                <div class="info" v-else>{{$t('tools.tools_alignIndex_page_tips_2')}}</div>
                <el-button size="small" plain round icon="el-icon-plus" v-permission="`tools:alignmentRouter:new`" @click="dialog.show = true;" v-if="queryType !== 'search'">{{$t('tools.tools_alignIndex_page_btn_3')}}</el-button>
            </div>
        </div>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize">
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
        <el-dialog :title="$t('tools.tools_alignIndex_page_dialog_1_title_1')" :visible.sync="dialog.show" destroy-on-close :width="'712px'" top="20px">
            <template slot="title">
                <div class="flex flex-align-center flex-justify-center" v-if="step === 1">
                    {{$t('tools.tools_alignIndex_page_dialog_1_title_1')}}<img style="height: 15px;margin-left: 4px;" src="../../../../static/images/limited-free.png" alt="free"/>
                </div>
                <template v-else>{{$t('tools.tools_alignIndex_page_dialog_1_title_2')}}</template>
            </template>
            <div v-show="step === 1">
                <div class="dialog-content" v-if="userInfo && userInfo.featureConfig">
                    <type-radio :list="typeRadioOptions" :active="typeRadioSelected" @change="typeRadioChange" style="justify-content: center;margin-bottom:15px;"></type-radio>
                    <div class="tips">
                        {{$t('tools.tools_alignIndex_page_dialog_1_tips_1', {type: dialog.supportFiles, size: userInfo.featureConfig.singleFileSize})}}
                        <!-- <el-tooltip placement="top">
                            <div slot="content" style="max-width: 300px;line-height: 1.5;">按照原文字符消耗流量。如上传双文档，按照原文文档计；如上传段段对照单文档，按照单文档字符计。</div>
                            <span class="margin-l-10 color-link cursor-pointer">流量扣费规则</span>
                        </el-tooltip> -->
                    </div>
                    
                    <div class="upload-files-content">
                        <div><!-- .xlsx,.xls,.csv,.rtf, -->
                            <upload-files 
                                ref="sourceUpload"
                                :multiple="false"
                                :title="`${$t('tools.tools_alignIndex_page_dialog_1_tips_2')}${typeRadioSelected==='double' ? $t('tools.tools_alignIndex_page_dialog_1_tips_3') : $t('tools.tools_alignIndex_page_dialog_1_tips_4')}`" 
                                :accept="dialog.accept"
                                :limit="10"
                                :size="userInfo.featureConfig.singleFileSize"
                                :params="{}"></upload-files>
                        </div>
                        <div class="margin-l-40" v-if="typeRadioSelected==='double'">
                            <upload-files 
                                ref="targetUpload"
                                :multiple="false"
                                :title="$t('tools.tools_alignIndex_page_dialog_1_tips_5')"
                                :accept="dialog.accept"
                                :limit="10"
                                :size="userInfo.featureConfig.singleFileSize"
                                :params="{}"></upload-files>
                        </div>
                    </div>
                </div>
                <div slot="footer" style="text-align: center;">
                    <el-button round plain size="small" @click="dialog.show = false">{{$t('tools.tools_alignIndex_page_dialog_1_btn_1')}}</el-button>
                    <el-button round type="primary" size="small" :loading="dialog.loading" @click="comfirmTaskEvent">{{$t('tools.tools_alignIndex_page_dialog_1_btn_2')}}</el-button>
                </div>
            </div>
            <div v-show="step === 2">
                <div class="dialog-content" style="width: 350px; margin: 0 auto 40px auto; ">
                    <div class="fonts-14 text-weight-600">{{$t('tools.tools_alignIndex_page_dialog_1_tips_6')}}</div>
                    <div class="language-direction ">
                        <div style="width:140px;">
                            <el-select v-model="dialog.source" filterable placeholder="" size="small" @change="sourceSelectChange">
                                <template v-for="value in languageDict">
                                    <el-option :key="value.key" :value="value.key" :label="value.name"></el-option>
                                </template>
                            </el-select>
                        </div>
                        <div @click="exchangeLanguage" style="cursor: pointer;">
                            <el-tooltip :content="$t('tools.tools_alignIndex_page_dialog_1_tips_7')" placement="top">
                                <svg-icon name="ib-transition"></svg-icon>
                            </el-tooltip>
                        </div>
                        <div style="width:140px;">
                            <el-select v-model="dialog.target" filterable placeholder="" size="small">
                                <template v-for="value in languageDict">
                                    <el-option :key="value.key" :value="value.key" :label="value.name" :disabled="value.key === dialog.source"></el-option>
                                </template>
                            </el-select>
                        </div>
                    </div>
                    <div class="fonts-14 text-weight-600 margin-t-20">{{typeRadioSelected==='double' ? $t('tools.tools_alignIndex_page_dialog_1_tips_3') : $t('tools.tools_alignIndex_page_dialog_1_tips_4')}}</div>
                    <template v-for="(file, index) in confirmSourceFiles">
                        <div class="fonts-15 margin-t-15 flex flex-align-center" :key="`${index}-cs`">
                            <div class="fonts-20">
                                <svg-icon :name="'ic-'+file.icon"></svg-icon>
                            </div>
                            <div class="margin-l-10 text-omission">
                                <el-tooltip :content="file.fileName" placement="top-start">
                                    <span>{{file.fileName}}</span>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template v-if="typeRadioSelected==='double'">
                        <div class="fonts-14 text-weight-600 margin-t-20">{{$t('tools.tools_alignIndex_page_dialog_1_tips_8')}}</div>
                        <template v-for="(file, index) in confirmTargetFiles">
                            <div class="fonts-15 margin-t-15 flex flex-align-center " :key="`${index}-ct`">
                                <div class="fonts-20">
                                    <svg-icon :name="'ic-'+file.icon"></svg-icon>
                                </div>
                                <div class="margin-l-10 text-omission">
                                    <el-tooltip :content="file.fileName" placement="top-start">
                                        <span>{{file.fileName}}</span>
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
                <div slot="footer" style="text-align: center;">
                    <el-button round plain size="small" @click="step = 1">{{$t('tools.tools_alignIndex_page_dialog_1_btn_3')}}</el-button>
                    <el-button round type="primary" size="small" :loading="dialog.loading" @click="startTaskEvent">{{$t('tools.tools_alignIndex_page_dialog_1_btn_4')}}</el-button>
                </div>
            </div>
        </el-dialog>
        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="deleteTask"></alert-dialog>
    </div>
</template>
<script>
import { mapState } from "vuex";
import TypeRadio from '@/components/TypeRadio';
import FullLoading from '@/components/FullLoading';
import AssetTabs from '../components/Tabs';
import CardList from '../components/CardList';
import UploadFiles from '@/components/UploadOss';
import AlertDialog from '@/components/AlertDialog';
import { toolsApi, commonApi } from '@/utils/api';
import config from '@/utils/config';
export default {
    components:{AssetTabs, CardList, UploadFiles, AlertDialog, FullLoading, TypeRadio},
    data(){
        return {
            config,
            refreshLoading: false,
            step: 1,
            listTimer: null,
            alertInfo:{
                message: this.$t('tools.tools_alignIndex_page_data_alertInfo_message'),
                type:'confirm', //alert ,confirm
                buttonName: this.$t('tools.tools_alignIndex_page_data_alertInfo_buttonName'),
                buttonType:'danger',
            },
            /* languageDict: {
                'zh':{key:'zh', name: this.$t('tools.tools_alignIndex_page_data_languageDict_zh_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_zh_shortName')},
                'en':{key:'en', name: this.$t('tools.tools_alignIndex_page_data_languageDict_en_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_en_shortName')},
                'ru':{key:'ru', name: this.$t('tools.tools_alignIndex_page_data_languageDict_ru_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_ru_shortName')},
                'fr':{key:'fr', name: this.$t('tools.tools_alignIndex_page_data_languageDict_fr_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_fr_shortName')},
                'de':{key:'de', name: this.$t('tools.tools_alignIndex_page_data_languageDict_de_name'),shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_de_shortName')},
                'es':{key:'es', name: this.$t('tools.tools_alignIndex_page_data_languageDict_es_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_es_shortName')},
                'pt':{key:'pt', name: this.$t('tools.tools_alignIndex_page_data_languageDict_pt_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_pt_shortName')},
                'ar':{key:'ar', name: this.$t('tools.tools_alignIndex_page_data_languageDict_ar_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_ar_shortName')},
                'ja':{key:'ja', name: this.$t('tools.tools_alignIndex_page_data_languageDict_ja_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_ja_shortName')},
                'ko':{key:'ko', name: this.$t('tools.tools_alignIndex_page_data_languageDict_ko_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_ko_shortName')},
                'th':{key:'th', name: this.$t('tools.tools_alignIndex_page_data_languageDict_th_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_th_shortName')},
                'vi':{key:'vi', name: this.$t('tools.tools_alignIndex_page_data_languageDict_vi_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_vi_shortName')},
                // 'km':{key:'km',name:'高棉语',shortName:'高'},
                'ms':{key:'ms', name: this.$t('tools.tools_alignIndex_page_data_languageDict_ms_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_ms_shortName')},
                'id':{key:'id', name: this.$t('tools.tools_alignIndex_page_data_languageDict_id_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_id_shortName')},
                'it':{key:'it', name: this.$t('tools.tools_alignIndex_page_data_languageDict_it_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_it_shortName')},
                'da': {key: 'da', name: this.$t('tools.tools_alignIndex_page_data_languageDict_da_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_da_shortName')},
                'nl': {key: 'nl', name: this.$t('tools.tools_alignIndex_page_data_languageDict_nl_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_nl_shortName')},
                'no': {key: 'no', name: this.$t('tools.tools_alignIndex_page_data_languageDict_no_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_no_shortName')},
                'sv': {key: 'sv', name: this.$t('tools.tools_alignIndex_page_data_languageDict_sv_name'), shortName: this.$t('tools.tools_alignIndex_page_data_languageDict_sv_shortName')},
            }, */
            dialog: {
                show: false,
                source: 'zh',
                target: 'en',
                loading:false,
                accept: ".docx,.doc,.txt,.xls,.xlsx,.ppt,.pptx,.idml", //,.pdf
                supportFiles: 'docx/doc/txt/xls/xlsx/ppt/pptx/idml', // /pdf
            },
            searchForm: {
                kw: '',
                sort: '1'
            },
            queryType: 'init',
            list: [],
            total: 0,
            pageSize: 20,
            pageNumber: 1,
            searchTimer: null,
            selectedTaskIds: [],
            typeRadioOptions:[{key:'double', name: this.$t('tools.tools_alignIndex_page_data_typeRadioOptions_name_1')},{key:'single', name: this.$t('tools.tools_alignIndex_page_data_typeRadioOptions_name_2')}],
            typeRadioSelected:'double',
            confirmSourceFiles: {},
            confirmTargetFiles: {},
        }
    },
    computed: {
		...mapState({
			userInfo: state => state.user.userData,
            languageDict: state => state.common.LANGUAGE_DICT,
		}),
	},
    methods:{
        initPage(type){
            if(type === 'init'){
                this.$refs.fullLoading.setShow();
            }
            if(type === 'reload'){
                this.refreshLoading = true;
            }
            let url = `${toolsApi.queryAlignTasklist}?pageNo=${this.pageNumber}&pageSize=${this.pageSize}&keyword=${encodeURI(this.searchForm.kw)}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.list = res.data.pager.records;
                    this.total = res.data.pager.total;
                }
            }).finally(()=>{
                this.$refs.fullLoading.setHide();
                this.refreshLoading = false;
            })
        },
        sourceSelectChange(value){
            if(value === this.dialog.target){
                if(value==='zh'){
                    this.dialog.target = 'en';
                }else{
                    this.dialog.target = 'zh';
                }
            }
        },
        exchangeLanguage(){
            let tmpOriginValue = this.dialog.source;
            let tmpTargetValue = this.dialog.target;
            this.dialog.source = tmpTargetValue;
            this.dialog.target = tmpOriginValue;
        },
        comfirmTaskEvent(){
            let sourceFiles = this.$refs.sourceUpload.getOssFiles();
            let targetFiles = this.typeRadioSelected==='double' ? this.$refs.targetUpload.getOssFiles() : [];
            if(sourceFiles.length === 0){
                this.$message.error(this.$t('tools.tools_alignIndex_page_method_comfirmTaskEvent_message_1'));
                return;
            }
            if(this.typeRadioSelected==='double'){
                if(targetFiles.length === 0){
                    this.$message.error(this.$t('tools.tools_alignIndex_page_method_comfirmTaskEvent_message_2'));
                    return;
                }
            }
            this.confirmSourceFiles = sourceFiles.map(item=>{
                let type = '.'+item.fileName.split('.').reverse()[0].toLowerCase();
                return {
                    ...item,
                    icon: config.FILE_TYPE_MATCH[type].icon,
                }
            });
            this.confirmTargetFiles = targetFiles.map(item=>{
                let type = '.'+item.fileName.split('.').reverse()[0].toLowerCase();
                return {
                    ...item,
                    icon: config.FILE_TYPE_MATCH[type].icon,
                }
            });
            // console.log('xxxx:::',this.confirmSourceFiles, this.confirmTargetFiles);
            this.step = 2;
        },
        startTaskEvent(){
            let sourceFiles = this.$refs.sourceUpload.getOssFiles();
            let targetFiles = this.typeRadioSelected==='double' ? this.$refs.targetUpload.getOssFiles() : [];
            
            if(sourceFiles.length === 0){
                this.$message.error(this.$t('tools.tools_alignIndex_page_method_startTaskEvent_message_1'));
                return;
            }
            if(this.typeRadioSelected==='double'){
                if(targetFiles.length === 0){
                    this.$message.error(this.$t('tools.tools_alignIndex_page_method_startTaskEvent_message_2'));
                    return;
                }
            }
            let originalFiles = {};
            if(this.typeRadioSelected === 'double'){
                originalFiles = {
                    source: sourceFiles,
                    target: targetFiles
                }
            }else{
                originalFiles = {
                    bilingual: sourceFiles,
                }
            }
            this.dialog.loading = true;
            let url = toolsApi.startAlignTask;
            this.$ajax.post(url,{
                sourceLang:this.dialog.source,
                targetLang:this.dialog.target,
                toolType:'SA',
                originalFiles: originalFiles,
                title:`${sourceFiles[0].fileName}`,
            }).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_alignIndex_page_method_startTaskEvent_message_3'));
                    this.dialog.show = false;
                    this.dialog.source = 'zh';
                    this.dialog.target = 'en';
                    this.step = 1;
                    this.initPage();
                }
            }).finally(()=>{
                this.dialog.loading = false;
            }) 
        },
        handleCurrentChange(num){
            this.pageNumber = num;
            this.initPage();
        },
        executeAction(key,params){
            switch (key){
                case 'excel':
                case 'tmx':
                case 'tbx':
                    this.downloadResult(key,params);
                    break;
                case 'delete':
                    this.$refs.alertDialogDom.setShow(true);
                    this.selectedTaskIds = [params.taskId];
                    break;
                case 'goto':
                    if (!this.$checkPermission('tools:alignmentRouter:detail')){
                        this.$message.error(this.$t('tools.tools_alignIndex_page_method_executeAction_message_1'));
                        return;
                    }
                    if(params.resultPoint === 'AI'){
                        this.$router.push({path: '/tools/align/detail', query:{id: params.taskId}});
                    }else if(params.resultPoint === 'OT'){
                        this.$router.push({path: '/tools/align/tiled', query:{id: params.taskId}});
                    }
                    break;
                case 'reload':
                    this.initPage('reload');
                    break;
            }
        },
        downloadResult(type,params){
            let url = commonApi.batchCreateDownloadTask;
            this.$ajax.post(url, {
                metadataIdList: [params.taskId], 
                title: params.title,
                metadataType: 'TOOLKIT_DA',
                moreOptions: {
                    exportFileType: type,
                    // resultPoint: 'OT',
                }
            }).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true} }));
                }
            }).finally(() =>{
                
            });
        },
        deleteTask(){
            let url = toolsApi.deleteTask;
            this.$ajax.post(url,{
                ids:this.selectedTaskIds,
                toolType:'SA'
            }).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_alignIndex_page_method_deleteTask_message_1'));
                    this.initPage();
                }
            })
        },
        inputSearchEvent(value){
            let self = this;
            if(value.length > 0){
                this.queryType = 'search';
            }else{
                this.queryType = 'init';
            }
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = setTimeout(()=>{
                self.initPage();
            },500);
        },
        typeRadioChange(item){
            this.typeRadioSelected = item.key;
        }
    },
    mounted(){
        let self = this;
        this.initPage('init');
        if(this.listTimer){
            clearInterval(this.listTimer);
        }
        this.listTimer = setInterval(()=>{
            self.initPage('reload');
        },5000);
    },
    destroyed(){
        clearInterval(this.listTimer);
    },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.align-index-page{
    // padding: 20px;
    
}
.free-notice{
    height: 26px;
    font-size: 12px;
    display: flex;
    padding: 0 12px;
    align-items: center;
    background-color: #FEF0F0;
    border-radius: 20px;
}
.dialog-content{
    margin-bottom: 40px;
    > .tips{
        text-align: center;
        font-size: 12px;
        margin-bottom: 20px;
    }
    > .language-direction{
        display: flex;
        // justify-content: center;
        align-items: center;
        margin-top: 15px; 
        svg{
            font-size: 18px;
            margin: 0 10px;
        }
    }
    > .upload-files-content{
        display: flex;
        > div{
            &:nth-child(0){
                padding-left: 40px;
            }
            flex: 1;
            min-width: 0;
            
        }
    }

}
</style>

